export default [
    {
        path: '/client/list',
        name: 'client-list',
        component: () => import('@/views/Client/ClientList.vue'),
    },
    {
        path: '/client/add',
        name: 'client-add-dashboard',
        component: () => import('@/views/Client/AddClient.vue'),
        meta: {
            pageTitle: 'New Client',
            breadcrumb: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    active: false,
                },
                {
                    text: 'Clients',
                    to: '/client/list',
                    active: false,
                },
                {
                    text: 'Add Client',
                    active: true,
                }
            ],
        },
    },
    {
        path: '/client/:slug',
        name: 'client-details',
        component: () => import('@/views/Client/ClientDetails.vue'),
    },
    {
        path: '/client/add',
        name: 'client-add',
        component: () => import('@/views/Client/ClientDetails.vue'),
        meta: {
            pageTitle: 'Clients',
            breadcrumb: [
                {
                    text: 'Clients',
                    active: false,
                },
                {
                    text: 'Add Client',
                    active: true,
                }
            ],
        },
    },
    {
        path: '/client/:slug/dashboard/public',
        name: 'client-public-dashboard',
        component: () => import('@/views/Client/PublicDashboard.vue'),
        meta: {
            layout: 'full',
            public: true
        },
    },
    {
        path: '/client/:slug/dashboard/edit-dashboard',
        name: 'client-edit-dashboard',
        component: () => import('@/views/Client/EditDashboard.vue'),
        meta: {
            pageTitle: 'Edit Dashboard',
        },
    },
    {
        path: '/client/:slug/dashboard/google-analytics',
        name: 'client-analytics-dashboard',
        component: () => import('@/views/Client/AnalyticDashboard.vue'),
    },
    {
        path: '/client/:slug/dashboard/google-ads',
        name: 'client-google-ads-dashboard',
        component: () => import('@/views/Client/Dashboards/GoogleAds/GoogleAdsDashboard.vue'),
    },
    {
        path: '/client/:slug/dashboard/dv360',
        name: 'client-google-ads-dashboard',
        component: () => import('@/views/Client/Dashboards/DV360/Dv360.vue'),
    },
    {
        path: '/client/:slug/dashboard/leadsquared',
        name: 'client-leadsquared-dashboard',
        component: () => import('@/views/Client/Dashboards/LeadSquaredAds/LeadSquared.vue'),
    },
    {
        path: '/client/:slug/dashboard/amazon-ads',
        name: 'client-amazon-ads-dashboard',
        component: () => import('@/views/Client/Dashboards/AmazonAds/AmazonAdsDashboard.vue'),
    },
    {
        path: '/client/:slug/dashboard/salesforce',
        name: 'client-salesforce-dashboard',
        component: () => import('@/views/Client/Dashboards/Salesforce/Salesforce.vue'),
    },
    {
        path: '/client/:slug/dashboard/meta-ads',
        name: 'client-facebook-ads-dashboard',
        component: () => import('@/views/Client/Dashboards/FacebookAds/FacebookAds.vue'),
        // props: { googleDashBoardPage: 'facebook-dashboard-page' }
        // props: { facebookDashBoardPage: 'facebook-dashboard-page' }
    },
    // {
    //     path: '/stores/:slug/',
    //     name: 'client-gmb-dashboard',
    //     component: () => import('@/views/Client/GmbV2.vue'),
    //     props: { customGmbValue: 'google-my-business' }
    // },
    {
        path: '/gmb/:slug/',
        name: 'client-gmb-dashboard',
        component: () => import('@/views/Client/GMBDashboard.vue'),
        props: { customGmbValue: 'google-my-business' }
    },
    {
        path: '/channels-dashboard/:slug',
        name: 'new-ui',
        component: () => import('@/views/clientFeature/MultiChannelConnectedDashboard.vue'),
      },
    {
        path: '/client/:slug/dashboard/google-ads/compare',
        name: 'client-google-ads-dashboard-comparison',
        component: () => import('@/views/Client/Dashboards/GoogleAds/Comparison/ComparisonDashboard.vue'),
    },
]