import jwtDefaultConfig from "./jwtDefaultConfig";
import Cookies from "js-cookie";
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  isAlreadyFetchingAccessToken = false;

  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          Cookies.remove(this.jwtConfig.storageTokenKeyName);
          Cookies.remove(this.jwtConfig.userEmailKey)
          Cookies.remove(this.jwtConfig.userNameKey);
          Cookies.remove(this.jwtConfig.userClientKey);
          Cookies.remove(this.jwtConfig.userGMBKey);
          Cookies.remove(this.jwtConfig.userDealersKey);
          Cookies.remove(this.jwtConfig.userAccessControlKey);
          Cookies.remove(this.jwtConfig.userTriggersKey);
          Cookies.remove(this.jwtConfig.configurations);
            //Tab item hide
          Cookies.remove(this.jwtConfig.gmbGeo);
          Cookies.remove(this.jwtConfig.gmbSummary);
          Cookies.remove(this.jwtConfig.gmbOrganic);
          Cookies.remove(this.jwtConfig.gmbPaid);
          Cookies.remove(this.jwtConfig.gmbPosts);
          Cookies.remove(this.jwtConfig.gmbReviews);
          Cookies.remove(this.jwtConfig.gmbStores);
          Cookies.remove(this.jwtConfig.adminUrl);
          Cookies.remove(this.jwtConfig.adminFirstName);
          Cookies.remove(this.jwtConfig.adminLastName);
          Cookies.remove(this.jwtConfig.adminID);
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback =>
      callback(accessToken)
    );
  }

  getToken() {
    return Cookies.get(this.jwtConfig.storageTokenKeyName);
  }
  
  removeUserData() {
    Cookies.remove(this.jwtConfig.userNameKey);
    Cookies.remove(this.jwtConfig.storageTokenKeyName);
    Cookies.remove(this.jwtConfig.userEmailKey)
    // Navbar item hiding feat.
    Cookies.remove(this.jwtConfig.userClientKey);
    Cookies.remove(this.jwtConfig.userGMBKey);
    Cookies.remove(this.jwtConfig.userDealersKey);
    Cookies.remove(this.jwtConfig.userAccessControlKey);
    Cookies.remove(this.jwtConfig.userTriggersKey);
    Cookies.remove(this.jwtConfig.configurations);
    //Tab item hide
    Cookies.remove(this.jwtConfig.gmbGeo);
    Cookies.remove(this.jwtConfig.gmbSummary);
    Cookies.remove(this.jwtConfig.gmbOrganic);
    Cookies.remove(this.jwtConfig.gmbPaid);
    Cookies.remove(this.jwtConfig.gmbPosts);
    Cookies.remove(this.jwtConfig.gmbReviews);
    Cookies.remove(this.jwtConfig.gmbStores);

    return;
  }
  setIdForSalesforce() {
    return Cookies.get(this.jwtConfig.salesforceId);
  }
  setIdForSalesforce(value) {
    Cookies.set(this.jwtConfig.salesforceId, value);
  }
  setToken(value) {
    Cookies.set(this.jwtConfig.storageTokenKeyName, value);
  }

  setUserName(name) {
    Cookies.set(this.jwtConfig.userNameKey, name);
  }

  setUserEmail(email) {
    Cookies.set(this.jwtConfig.userEmailKey, email);
  }

  setAdminClientConfigurationsEnabled(client) {
    Cookies.set(this.jwtConfig.configurations, JSON.stringify(client));
  }
  // Navbar item hiding feat.
  setAdminClientEnabled(client) {
    Cookies.set(this.jwtConfig.configurations, client);
  }
  setAdminGMBEnabled(gmb) {
    Cookies.set(this.jwtConfig.userGMBKey, gmb);
  }
  // ---
  setAdminGMBGeoEnabled(geo) {
    Cookies.set(this.jwtConfig.gmbGeo, geo);
  }
  setAdminGMBSummaryEnabled(summary) {
    Cookies.set(this.jwtConfig.gmbSummary, summary);
  }
  setAdminGMBOrganicEnabled(organic) {
    Cookies.set(this.jwtConfig.gmbOrganic, organic);
  }
  setAdminGMBPaidEnabled(paid) {
    Cookies.set(this.jwtConfig.gmbPaid, paid);
  }
  setAdminGMBPostEnabled(post) {
    Cookies.set(this.jwtConfig.gmbPosts, post);
  }
  setAdminGMBReviewEnabled(review) {
    Cookies.set(this.jwtConfig.gmbReviews, review);
  }
  setAdminGMBStoreEnabled(stores) {
    Cookies.set(this.jwtConfig.gmbStores, stores);
  }
  setAdminImageUrl(image) {
    Cookies.set(this.jwtConfig.adminUrl, image);
  }
  setAdminID(id) {
    Cookies.set(this.jwtConfig.adminID, id);
  }
  setAdminFirstName(name) {
    Cookies.set(this.jwtConfig.adminFirstName, name);
  }
  setAdminLastName(name) {
    Cookies.set(this.jwtConfig.adminLastName, name);
  }
  //--------
  setAdminGMBDealersEnabled(gmbd) {
    Cookies.set(this.jwtConfig.userDealersKey, gmbd);
  }
  setAdminAccessControlEnabled(access) {
    Cookies.set(this.jwtConfig.userAccessControlKey, access);
  }
  setAdminTriggersControlEnabled(trigger) {
    Cookies.set(this.jwtConfig.userTriggersKey, trigger);
  }
  setAdminRole(role){
    Cookies.set(this.jwtConfig.roleForConfiguration, role);
  }
  setAdminImageUrl(role){
    Cookies.set(this.jwtConfig.adminUrl, role);
  }
  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }
  setAdminImage(role){
    Cookies.set(this.jwtConfig.adminUrl, role);
  }
  logout() {
    return this.axiosIns.post(
      this.jwtConfig.logoutEndpoint,
      {},
      {
        headers: {
          Authorization: `${this.jwtConfig.tokenType} ${this.getToken()}`
        }
      }
    );
  }
  checklogin() {
    return this.axiosIns.get(
      this.jwtConfig.checkLoginEndpoint,
      {},
      {
        headers: {
          Authorization: `${this.jwtConfig.tokenType} ${this.getToken()}`
        }
      }
    ).then((response) =>{
      this.setAdminClientEnabled(response.data.config.clients);
      this.setAdminGMBEnabled(response.data.config.gmb.enabled);
      this.setAdminGMBDealersEnabled(response.data.config.gmb_for_dealers);
      this.setAdminAccessControlEnabled(response.data.config.access_control);
      this.setAdminTriggersControlEnabled(response.data.config.triggers);
      this.setAdminGMBGeoEnabled(response.data.config.gmb.items.geo)
      this.setAdminGMBSummaryEnabled(response.data.config.gmb.items.summary)
      this.setAdminGMBOrganicEnabled(response.data.config.gmb.items.organic)
      this.setAdminGMBPostEnabled(response.data.config.gmb.items.posts)
      this.setAdminGMBPaidEnabled(response.data.config.gmb.items.paid)
      this.setAdminGMBReviewEnabled(response.data.config.gmb.items.reviews)
      this.setAdminGMBStoreEnabled(response.data.config.gmb.items.stores)
      this.setAdminImageUrl(response.data.adminProfileImageUrl.s3URL)
      this.setAdminLastName(response.data.lastname)
      this.setAdminFirstName(response.data.firstname)
      this.setAdminID(response.data.adminID)
      this.setAdminRole(response.data.role)
    }).catch((error) =>{
      console.log(error.data);
    });
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }
}
